import React from 'react';

const TagFile = (props) => {
    const handleButtonClick = (value) => {
        props.onButtonPress(value);
    };
    
    return (
        <div>
            <button onClick={() => handleButtonClick(0)} className='btn btn-sm btn-success'>Agente en Canal 0</button>
            <button onClick={() => handleButtonClick(1)} className='btn btn-sm btn-success'>Agente en Canal 1</button>
            <button onClick={() => handleButtonClick(-1)} className='btn btn-sm btn-warning'>Otro/No queda claro</button>
        </div>
    );
};
export default TagFile;