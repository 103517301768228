import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

const Ranking = () => {
    const {
        isAuthenticated
    } = useAuth0();
    if (!isAuthenticated){
        console.log("no logueado")
	window.location.href = "https://channel-classification.emotionalscoring.io/";
    }
    return (
        <div>
            <iframe style={{width: "100%",height: "900px",border: "0px"}} src="https://reports.emotionalscoring.io/public/dashboard/e4993d30-143a-4398-ab2c-9c4d2aec95f0" />
        </div>
    );
};

export default Ranking;
