import React from 'react';
import Tooltip from '@mui/material/Tooltip';

const GoalBar = (props) => {
    return (
        <div className='progress-bar-container'>
            {/* <Tooltip title="Avance / Meta del equipo" placement="top" arrow> */}
                <h6>Avance equipo / Meta</h6>
                <div className="progress-container">
                    <div className="progress" style={{width: (props.progress/props.goal*0)+"100%"}}>
                        <div className="progress-text">{props.progress}/{props.goal}</div>
                        <Tooltip title="Tu aporte" placement="bottom" arrow>
                            <div className="my-progress" style={{width: (props.myProgress/props.progress*100)+"%"}}>{props.myProgress}</div>
                        </Tooltip>
                    </div>
                </div>
            {/* </Tooltip> */}
        </div>
    );
};
export default GoalBar;
