import React from "react";

import logo from "../assets/Logotipo_original.svg";

const Hero = () => (
  <div className="text-center hero my-5">
    <img className="mb-3 app--logo" src={logo} alt="Emotional Scoring" width="220" />
    <br />
    <h1 className="mb-4">Clasificador de canales</h1>

    <p className="lead">
      Ayudanos a clasificar los archivos de audio para mejorar nuestra detección de agentes y clientes.
    </p>
  </div>
);

export default Hero;
