import React, { useState, useEffect } from 'react';
import TagFile from './TagFile';
import GoalBar from './GoalBar'
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";

const Tagging = (props) => {
    const {
        isAuthenticated
    } = useAuth0();
    if (!isAuthenticated){
        console.log("no logueado")
	window.location.href = "https://channel-classification.emotionalscoring.io/";
    }

    const [data, setData] = useState([]);
    const base_url = "https://api-service.emotionalscoring.io/"

    const handleButtonPress = async (value) => {
        const body = { agent_channel: value, client_channel: value< 0 ? -1 : Math.pow(value-1, 2)}
        const headers = {"X-TENANT-ID": "cajalosandes", "X-USER-EMAIL": localStorage.getItem('x-user-email')}
        await axios.put(base_url+'audiofiles/audio-validate/' + data._id, body,{headers: headers});
        fetchData();
    };
    
    const fetchData = async () => {
        try {
            const headers = {"X-TENANT-ID": "cajalosandes", "X-USER-EMAIL": localStorage.getItem('x-user-email')}
            const response = await axios.get(base_url+'audiofiles/audio-sample',{headers: headers});
            const myProgress = await axios.get(base_url+"classificationUsers",{headers: headers})
            const data = {...response.data.data, ...{myProgress: myProgress.data.total}};
            setData(data);
            console.log(data)
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const progress = data.total_validated;
    const goal = 140;

    return (
        <div>
            <div>
                <GoalBar progress={progress} goal={goal} myProgress={data.myProgress} />
            </div>
            <h2>Clasificar</h2>
            <div className='dialog-wrapper'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Canal</th>
                            <th>Dialogo</th>
                        </tr>
                    </thead>
                    <tbody>
                    {data && data.dialog ? (
                        data.dialog.map((item, index) => (
                            <tr key={index}>
                                <td>Canal {item.channel} :</td>
                                <td>{item.dialog}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="2">Obteniendo llamada...</td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
            <TagFile onButtonPress={handleButtonPress} />
        </div>
    );
};


export default Tagging;
